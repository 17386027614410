// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import settings from '../../settings';
import httpService, { storedAccessToken } from '../../services/http';

type Props = {
  children: React.ReactNode;
};
type contextType = {
  setLicenseData: any;
  simulationType: string;
  name: string;
  availableSimulations: number;
  startDate: string;
  endDate: string;
};

export const LicenseDataContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const LicenseDataProvider = ({ children }: Props) => {
  const [simulationType, setSimulationType] = useState('');
  const [name, setName] = useState('');
  const [availableSimulations, setAvailableSimulations] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  /**
   * @description is called to set license data
   */
  const setLicenseData = () => {
    httpService
      .get('licence/', {}, 'json', true)
      .then((response) => {
        // filter data for current product
        const license = response.data.filter((r) => r.simulationtype === settings.product);
        // set data from API
        setSimulationType(license[0].simulationtype);
        setName(license[0].name);
        setAvailableSimulations(license[0].available_simulations);
        setStartDate(license[0].start_date);
        setEndDate(license[0].end_date);
        return true;
      })
      .catch((error) => error);
  };

  useEffect(() => {
    if (storedAccessToken()) {
      setLicenseData();
    }
  }, []);
  return (
    <LicenseDataContext.Provider
      value={{
        setLicenseData,
        simulationType,
        name,
        availableSimulations,
        startDate,
        endDate,
      }}
    >
      {children}
    </LicenseDataContext.Provider>
  );
};

export const useLicenseDataContext = (): contextType => {
  const context = useContext(LicenseDataContext);

  if (context === undefined) {
    throw new Error('useLicenseDataContext must be used within a UserDataProvider');
  }

  return context;
};
export default LicenseDataProvider;
