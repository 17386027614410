import settings from '../../settings';

/**
 * This file holds all relevant dummy/mock data for the App context and
 * tests that are related to it.
 */

/**
 * no components should be included in the app data, only boolean, strings and maybe numbers
 */
export const defaultAppData = {
  enableResultDownload: true,
  enableDemo: false,
  enableVoucherCode: false,
  enableCompanyRegistration: false,
  fixedLanguage: null,
  useDashboard: true,
  showAccountInfo: true,
  requiresAuth: true,
  contactButton: false,
  showParamsResult: true,
  pdfReportOnResultsPage: false,
  logoLink: 'https://tlk-energy.de/',
  parameterPageType: 'default',
  showAppBarLogo: true,
  // choose between logo and 'powered by tlk' button in AppBar Component
  loginOptions: {
    email: true,
  },
  tilMediaUrl: 'https://tgvw74a3r7.execute-api.eu-central-1.amazonaws.com/default/tilmedia',
  hasInterfaceToCPQ: false,
  setHasInterfaceToCPQ: () => {},
};
// inverse/changed appData for tests
export const inverseAppData = {
  ...defaultAppData,
  enableResultDownload: false,
  enableDemo: true,
  enableVoucherCode: true,
  enableCompanyRegistration: true,
  fixedLanguage: 'de',
  useDashboard: false,
  showAccountInfo: false,
  requiresAuth: false,
  contactButton: true,
  showParamsResult: false,
  pdfReportOnResultsPage: true,
  logoLink: '/test/link',
  showAppBarLogo: false,
  parameterPageType: 'stepper',
  loginOptions: {
    email: true,
    microsoft: {
      redirectUri: 'activate-microsoft/',
      clientId: '6b973a5f-e5be-47e1-9be9-58661f273755',
    },
  },
};
export const appDataTlksim = {
  ...defaultAppData,
  enableDemo: true,
  logoLink: 'https://apps.tlk-energy.de/',
  loginOptions: {
    email: true,
    microsoft: {
      redirectUri: 'activate-microsoft/',
      clientId: '6b973a5f-e5be-47e1-9be9-58661f273755',
    },
  },
};
export const appDataTheda = { ...defaultAppData, enableVoucherCode: true, logoLink: 'https://apps.tlk-energy.de/' };
export const appDataHpdesigner = { ...defaultAppData, enableDemo: true };
export const appDataAerosim = {
  ...defaultAppData,
  fixedLanguage: 'en',
  logoLink: 'https://apps.tlk-energy.de/en/aerosim',
};
export const appDataAirflowsim = {
  ...defaultAppData,
  enableCompanyRegistration: true,
  fixedLanguage: 'de',
  useDashboard: false,
  showAccountInfo: false,
  showParamsResult: false,
  pdfReportOnResultsPage: true,
  logoLink: 'https://www.airflow.de/',
};
export const appDataPluggsim = {
  ...defaultAppData,
  fixedLanguage: 'de',
  useDashboard: false,
  showParamsResult: false,
  logoLink: 'https://www.pluggit.com/',
  parameterPageType: 'stepper',
};
export const appDataNeasim = { ...defaultAppData, showAccountInfo: false, logoLink: 'https://www.neuman-esser.de/' };
export const appDataZieglersim = {
  ...defaultAppData,
  fixedLanguage: 'de',
  showAccountInfo: false,
  logoLink: 'https://www.zieglerhaus.de/',
};
export const appDataHeatensim = {
  ...defaultAppData,
  fixedLanguage: 'en',
  showAccountInfo: false,
  logoLink: 'https://www.heaten.com/',
};
export const appDataLogph = { ...defaultAppData, showAccountInfo: false, requiresAuth: false, logoLink: '/en' };
export const appDataDanfosssim = {
  ...defaultAppData,
  loginOptions: {
    microsoft: {
      redirectUri: 'activate-azure/',
      clientId: 'f0d19a86-3dd6-48fa-b305-e3eaf2167db2',
    },
  },
};
export const appDataKautexsim = {
  ...defaultAppData,
  loginOptions: {
    microsoft: {
      redirectUri: 'activate-microsoft/',
      clientId: '44e7846f-f3d8-4d4a-a825-660a0a12ce73',
    },
  },
};
export const appDataVaillantsim = {
  ...defaultAppData,
  fixedLanguage: 'en',
  showAccountInfo: false,
  logoLink: 'https://www.vaillant.de/',
};

export const appDataWeisstechniksim = {
  ...defaultAppData,
  fixedLanguage: 'en',
  showAccountInfo: false,
  logoLink: 'https://www.weiss-technik.com/',
};

export const appDataBdrsim = {
  ...defaultAppData,
  fixedLanguage: 'en',
  showAccountInfo: false,
  logoLink: 'https://www.bdrthermeagroup.com/',
};

const getAppData = () => {
  if (settings.product === 'theda') {
    return appDataTheda;
  }

  if (settings.product === 'airflowsim') {
    return appDataAirflowsim;
  }

  if (settings.product === 'aerosim') {
    return appDataAerosim;
  }

  if (settings.product === 'hpDesigner') {
    return appDataHpdesigner;
  }

  if (settings.product === 'pluggsim') {
    return appDataPluggsim;
  }

  if (settings.product === 'neasim') {
    return appDataNeasim;
  }

  if (settings.product === 'zieglersim') {
    return appDataZieglersim;
  }

  if (settings.product === 'heatensim') {
    return appDataHeatensim;
  }

  if (settings.product === 'danfosssim') {
    return appDataDanfosssim;
  }

  if (settings.product === 'kautexsim') {
    return appDataKautexsim;
  }

  if (settings.product === 'vaillantsim') {
    return appDataVaillantsim;
  }

  if (settings.product === 'weisstechniksim') {
    return appDataWeisstechniksim;
  }

  if (settings.product === 'bdrsim') {
    return appDataBdrsim;
  }

  if (settings.product === 'logph' || settings.product === 'hx' || settings.product === 'mccabethiele') {
    return appDataLogph;
  }

  return appDataTlksim;
};

const appData = getAppData();
export default appData;
