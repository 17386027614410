// @ts-nocheck
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportingData from 'highcharts/modules/export-data';
import Boost from 'highcharts/modules/boost';
import highchartsGauge from 'highcharts/modules/solid-gauge';
import Debugger from 'highcharts/modules/debugger';
import Pattern_fill from 'highcharts/modules/pattern-fill';
import Draggable_points from 'highcharts/modules/draggable-points';
import Sankey from 'highcharts/modules/sankey';
import { useTranslation } from 'react-i18next';
import chartOptions from '../../charts/constants/chartOptionConstants';
// to access the history in the HTTP file we need to creat a 'custom' history object which is than passed as a
// history-prop to the Router-Component.
import theme from '../../../theme';
import AppProvider from '../../../context/AppProvider';
import appData from '../../../context/AppProvider/appContextData';
import StructureProvider from '../../../context/StructureProvider';
import UIParameterProvider from '../../../context/UIParameterProvider';
import PlotDataProvider from '../../../context/PlotDataProvider';
import UserDataProvider from '../../../context/UserDataProvider';
import LicenseDataProvider from '../../../context/LicenseDataProvider';
import ModalProvider from '../../../context/ModalProvider';
import vistasansmed from '../../../assets/fonts/vistasansmed-020415006EmigreWebOnly.woff';
import vistasansbook from '../../../assets/fonts/vistasansbook-020415006EmigreWebOnly.woff';
import robotolight from '../../../assets/fonts/Roboto-Light.woff';
import noto from '../../../assets/fonts/noto-sans-v12-latin-regular.woff';
import ambit from '../../../assets/fonts/Ambit-Regular.woff';
import settings from '../../../settings';
import { StyledToastContainer } from '../../common/Toaster';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: vistasansmed;
    src: url(${vistasansmed}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: vistasansbook;
    src: url(${vistasansbook}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ambit;
    src: url(${ambit}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: noto;
    src: url(${noto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: robotolight;
    src: url(${robotolight}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
// Set global options for highcharts. chartOptions are set depending on App
Highcharts.setOptions(chartOptions);
HC_more(Highcharts);
Boost(Highcharts);
HC_exporting(Highcharts);
HC_exportingData(Highcharts);
highchartsGauge(Highcharts);
Debugger(Highcharts);
Pattern_fill(Highcharts);
Draggable_points(Highcharts);
Sankey(Highcharts);
type Props = {
  children: React.ReactNode;
};

/**
 * This is the base component that nests all the pages and holds the router.
 * @param
 * @returns {*}
 * @constructor
 */
export const AppComponent = ({ children }: Props) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(appData.fixedLanguage);
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyle />
            <ModalProvider>
              {settings.product === 'pandemic' ? (
                <AppProvider>{children}</AppProvider>
              ) : (
                <AppProvider>
                  <UserDataProvider>
                    <StructureProvider>
                      <UIParameterProvider>
                        <LicenseDataProvider>
                          <PlotDataProvider>
                            {children}
                            <StyledToastContainer />
                          </PlotDataProvider>
                        </LicenseDataProvider>
                      </UIParameterProvider>
                    </StructureProvider>
                  </UserDataProvider>
                </AppProvider>
              )}
            </ModalProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default AppComponent;
