// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import httpService from '../../services/http';
import { isEmpty } from '../../utils/helper/helper';
import { useStructureContext } from '../StructureProvider';

type Props = {
  children: React.ReactNode;
};
type contextType = {
  isLoading: boolean;
  setIsLoading: any;
  refrigerantList: any;
  refrigerantSelectList: any;
  gasList: any;
  gasSelectList: any;
  getMediaDataRefrigerant: any;
  getMediaDataMoistAir: any;
  parameter: any;
  setParameter: any;
  setInitialParameter: any;
};

export const UIParameterContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const UIParameterProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [refrigerantList, setRefrigerantList] = useState([]);
  const [refrigerantSelectList, setRefrigerantSelectList] = useState([]);
  const [gasList, setGasList] = useState([]);
  const [gasSelectList, setGasSelectList] = useState([]);
  const [parameter, setParameter] = useState([]);
  const structureContext = useStructureContext();

  /**
   * Function can makes sure that old simulations work with up-to-date parameter structure
   * If no parameters are passed, default initial parameter are set.
   * @param parameter list of parameters
   * @returns {*}
   */
  const setInitialParameter = (parameters: any = {}) => {
    let initialValues = {};
    ({ initialValues } = structureContext);
    const withInitial = { ...initialValues, ...parameters };
    setParameter([withInitial]);
    if (!isEmpty(withInitial)) setIsLoading(false);
  };

  /**
   * @description is called to get list of available refrigerants
   */
  const getRefrigerantList = async () => {
    const response = await httpService.get('fluiddata/logphstatechart/', {}, 'json', true);
    const listRefrigerants = response?.data || [];
    const selectList = listRefrigerants.map((l) => ({
      value: l.id,
      label: l.name,
    }));
    setRefrigerantList(listRefrigerants);
    setRefrigerantSelectList(selectList);
  };

  /**
   * @description is called to get list of available gases
   */
  const getGasList = async () => {
    const response = await httpService.get('fluiddata/hxstatechart/', {}, 'json', true);
    const listGases = response?.data || [];
    const selectList = listGases.map((l) => ({
      value: l.id,
      label: l.name,
    }));
    setGasList(listGases);
    setGasSelectList(selectList);
  };

  /**
   * @description is called to get media data of one refrigerant,
   * @param idRefrigerant id of refrigerant in database
   */
  const getMediaDataRefrigerant = async (idRefrigerant?: number) => {
    if (!idRefrigerant) return {};
    const response = await httpService.get(`fluiddata/logphstatechart/${idRefrigerant}/`, {}, 'json', true);
    return response?.data || {};
  };

  /**
   * @description is called to get h1px diagram media data
   * for moist air at a given pressure (referenced by idGas)
   * @param id of air pressure
   */
  const getMediaDataMoistAir = async (id?: number) => {
    const response = await httpService.get(`fluiddata/hxstatechart/${idGas}/`, {}, 'json', true);
    const hxStateChartDataPlot = {};
    hxStateChartDataPlot.name = response?.data?.name;
    hxStateChartDataPlot.plot_settings = response?.data?.plot_settings || [];
    hxStateChartDataPlot.h1pxIsolines = response?.data?.h_isolines || [];
    hxStateChartDataPlot.TIsolines = response?.data?.t_isolines || [];
    hxStateChartDataPlot.phiIsolines = response?.data?.phi_isolines || [];
    hxStateChartDataPlot.areaUnsaturatedAir = response?.data?.area_unsaturated_air || [];
    return hxStateChartDataPlot;
  };

  useEffect(() => {
    getRefrigerantList();
    getGasList();
  }, []);
  useEffect(() => {
    setInitialParameter();
  }, [structureContext.isLoadingStructure]);
  return (
    <UIParameterContext.Provider
      value={{
        refrigerantList,
        refrigerantSelectList,
        gasList,
        gasSelectList,
        getMediaDataRefrigerant,
        getMediaDataMoistAir,
        isLoading,
        setIsLoading,
        parameter,
        setParameter,
        setInitialParameter,
      }}
    >
      {children}
    </UIParameterContext.Provider>
  );
};

export const useUIParameterContext = (): contextType => {
  const context = useContext(UIParameterContext);

  if (context === undefined) {
    throw new Error('useUIParameterContext must be used within a UIParameterProvider');
  }

  return context;
};
export default UIParameterProvider;
