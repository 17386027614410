import type { Quantity, Unit } from '../../context/StructureProvider';

/**
 * @description returns units for specific quantity
 * @param units available units
 * @param quantity to get units for
 */
export const getRelatedUnits = (units: Array<Unit>, quantity: Quantity): Array<Unit> => {
  const relatedUnits = units.filter((obj) => obj.quantity_id === quantity.id);
  return relatedUnits;
};

/**
 * @description returns all available units
 * @param units available units
 */
export const getAllUnits = (units: Array<Unit>): Array<Unit> => {
  return units;
};

/**
 * @description returns quantity of specific unit
 * returns undefined if no quantity can be found for unitName.
 * @param units available units
 * @param quantity available quantities
 * @param unitName to get quantity for
 */
export const getQuantityOfUnit = (
  units: Array<Unit>,
  quantities: Array<Quantity>,
  unitName: string
): Quantity | void => {
  const unit = units.find((obj) => obj.name === unitName);
  const quantity = unit ? quantities.find((obj) => obj.id === unit.quantity_id) : undefined;
  return quantity;
};

/**
 * @description checks if both units exist and belong to the same quantity
 * @param units available units
 * @param quantity available quantities
 * @param unit1
 * @param unit2
 * @return {}
 */
export const unitsCompatible = (
  units: Array<Unit>,
  quantities: Array<Quantity>,
  unit1: string,
  unit2: string
): boolean => {
  const quantity1 = getQuantityOfUnit(units, quantities, unit1);
  const quantity2 = getQuantityOfUnit(units, quantities, unit2);
  return quantity1 === quantity2 && quantity1 !== undefined;
};

/**
 * @description returns value transformed to new unit,
 * if current and new unit represent the same quantity,
 * else the current value is returned.
 * @param units available units
 * @param quantity available quantities
 * @param currentUnitName
 * @param currentValue
 * @param newUnitName
 * @return {}
 */
export const unitConversion = (
  units: Array<Unit>,
  quantities: Array<Quantity>,
  currentUnitName: string,
  currentValue: number | boolean | string,
  newUnitName: string
): number => {
  if (typeof currentValue === 'boolean' || typeof currentValue === 'string') return 0;
  const currentQuantity = getQuantityOfUnit(units, quantities, currentUnitName);
  const newQuantity = getQuantityOfUnit(units, quantities, newUnitName);

  if (currentQuantity === newQuantity) {
    const currentUnit = units.find((obj) => obj.name === currentUnitName);
    const newUnit = units.find((obj) => obj.name === newUnitName);
    if (!currentUnit || !newUnit) return currentValue; // rather return undefined?

    const newValue = ((currentValue + currentUnit.offset) * currentUnit.factor) / newUnit.factor - newUnit.offset;
    // round to 9 decimal places
    return Number(newValue.toPrecision(9));
  }

  return currentValue;
};
