import React, { useContext, useState } from 'react';
import appData from './appContextData';

type Props = {
  children: React.ReactNode;
};
type contextType = {
  enableVoucherCode: boolean;
  enableResultDownload: boolean;
  enableDemo: boolean;
  fixedLanguage: string | null;
  logoLink: string;
  useDashboard: boolean;
  showAccountInfo: boolean;
  requiresAuth: boolean;
  showParamsResult: boolean;
  pdfReportOnResultsPage: boolean;
  enableCompanyRegistration: boolean;
  contactButton: boolean;
  parameterPageType: string; // typing with "'default' | 'stepper'"" does not work somehow, but would be better
  showAppBarLogo: boolean;
  loginOptions: {
    email?: boolean;
    microsoft?: {
      clientId: string;
      redirectUri: string;
    };
  };
  tilMediaUrl: string;
  hasInterfaceToCPQ: boolean;
  setHasInterfaceToCPQ: any;
};

export const AppContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

/**
 * enableResultDownload: enable result download on dashboard
 * enableDemo: enable demo button for login
 * enableVoucherCode: enable voucher code field at registration
 * enableCompanyRegistration: enable company name field at registration
 * contactButton: display button with link to contact TLK
 * fixedLanguage: defines a fixed language, 'null' if non is given
 * logoLink: defines the link that is used on the logo in NewSimulation and ResultsPage
 * useDashboard: enables use of dashboard within the App, influences redirections on different pages
 * showAccountInfo: ahows/hides a button that leads to account infos (usually in footer), how is check out handled?
 * showParamsResult: shows/hides the parameter results page
 * pdfReportOnResultsPage: adds button in ResultsMenu for download of results pdf
 * parameterPageType: defines the appearance of the new simulation page for parameter input
 * loginOptions: defines which login options are avialble and defines additional information required
 * hasInterfaceToCPQ: for apps connected to a CPQ platform, default: false
 */
const AppProvider = ({ children }: Props) => {
  // app data that must be changed after initial load of app, must be defined as state
  const [hasInterfaceToCPQ, setHasInterfaceToCPQ] = useState(false);

  // appData contains all settings that are constant over the lifecycle of the app
  return (
    <AppContext.Provider
      value={{
        ...appData,
        hasInterfaceToCPQ,
        setHasInterfaceToCPQ,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): contextType => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};
export default AppProvider;
