// @ts-nocheck
import React, { useState, useContext, useMemo } from 'react';
import httpService, { storedAccessToken } from '../../services/http';
import { isEmpty } from '../../utils/helper/helper';
import { useUserDataContext } from '../UserDataProvider';
import * as unitsHelper from '../../utils/helper/units';
import settings from '../../settings';

type Props = {
  children: React.ReactNode;
};
type VariableInput = {
  start: Array<Array<number>>;
  min: Array<number>;
  max: Array<number>;
  unit: Array<string>;
  displayUnit: Array<string>;
  names: Array<any>;
  columnNames: Array<string>;
};
export type Table = {
  start: Array<Array<number>>;
  min: Array<number>;
  max: Array<number>;
  unit: Array<string>;
  displayUnit: Array<string>;
  columnNames: Array<string>;
  maxRows: number;
};
type Form = {
  name: string;
};
export type VariableFmu = {
  name: string;
  type: string;
  start: any;
  min: any;
  max: any;
  unit: string;
  displayUnit: string;
  description: string;
  declaredType: {
    items: Array<{
      name: string;
      value: string;
      description: string;
    }>;
  };
};
export type Variable = {
  name: string;
  inactive: boolean;
  alias: string;
  start: number | boolean | string;
  type: string;
  causality?: string;
  inputType: string;
  uiType: string;
  min: number;
  max: number;
  unit: string;
  step?: number;
  displayUnit: string;
  description?: string;
  calculation?: {
    variables: Record<string, any>;
    formula: string;
  };
  variableInput: VariableInput;
  arrays: Table;
  selectOptions?: Array<{
    value: number;
    label: string;
    key?: any;
  }>;
  dependency?: {
    alias: number;
    hideValue?: any;
    showValue?: any;
  };
  dataSource?: 'csv' | 'manual';
};
type SvgPlotElement = {
  id: string;
  type: 'text' | 'showHide' | 'dynStrokeWidth' | 'translate' | 'changeAttr' | 'event';
  variable?: {
    value: string;
    inputType?: string;
    operation?: string;
    variables?: Object;
  };
  header?: string;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  min?: {
    varValue: number;
    attrValue: number;
  };
  max?: {
    varValue: number;
    attrValue: number;
  };
  dependency?: {
    type: 'showValue' | 'hideValue';
    value: number | string | boolean;
    variable: string;
  };
  translate?: {
    formulaXvalue: string;
    formulaYvalue: string;
    variables: {
      [key: string]: string;
    };
  };
  attributesToModify: Array<{
    attrName: string;
    formulaAttrValue: string;
    variables: {
      [key: string]: string;
    };
  }>;
  events?: Array<{
    type: 'onClick';
    action: 'showPlots' | 'changeMenu';
    plots: Array<string>;
    targetMenu: string;
    defaultBorderColor?: string;
    highlightBorderColor?: string;
  }>;
};
export type Plot = {
  name: string;
  title: string;
  subtitle?: string;
  type: string;
  categories?: Array<string>;
  xTitle: string;
  xUnit: string;
  series: Array<any>;
  yTitle: string;
  yUnit: string;
  options?: any;
  data: Array<Record<string, any>>;
  subType?: string;
  synchronized?: boolean;
  showTimeMarker?: boolean;
  dateTimeFormat?: string;
  fluidId?: number;
  comp?: Record<string, any>;
  plotBands?: Array<any>;
  imageFileName?: string;
  imageId?: number;
  customColors?: Array<any>;
  stacked?: boolean;
  pieLabelType?: string;
  isElevatedCard?: boolean;
  elements?: Array<SvgPlotElement>;
  datasetType?: string;
  resName?: string;
  file?: {
    img: string;
    name: string;
    id: number;
  };
};

// defnition of a variable in plot UI structure
export type PlotUiVariable = { value: string; variables: Record<string, any>; operation: string };

export type UiResultsConfig = Array<{
  name: string;
  plots: Array<Plot>;
  icon?: string;
}>;
export type Quantity = {
  id: number;
  name: string;
};
export type Unit = {
  id: number;
  factor: number;
  name: string;
  offset: number;
  quantity_id: number;
};
type SubMenu = {
  name: string;
  form: Form;
  variables: Array<Variable>;
};
type MainMenu = {
  name: string;
  icon: string;
  stepperMenuText: string;
  subMenu: Array<SubMenu>;
};
export type UiSetupConfig = Array<Partial<MainMenu>>;
export type contextType = {
  getFmuList: (...args: Array<any>) => any;
  selectedFmu: any;
  setSelectedFmu: any;
  getFmuData: (...args: Array<any>) => any;
  fmuSelectList: Array<{
    value: number;
    label: string;
  }>;
  isLoadingStructure: boolean;
  uiSetupConfig: UiSetupConfig;
  uiInputConfig: UiSetupConfig;
  isInteractive: boolean;
  setIsInteractive: any;
  inputsAvailable: boolean;
  menuLevel1: Array<string>;
  menuLevel2: Array<Array<string>>;
  parameterMenuIcons: Array<string>;
  stepperMenuTexts: Array<string>;
  initialValues: any;
  initialInputValues: any;
  getMappedFmuUiValues: (...args: Array<any>) => any;
  getVariablesOfSubMenu: (...args: Array<any>) => any;
  getVariableUnit: (...args: Array<any>) => any;
  variables: Array<any>;
  uiResultsConfig: UiResultsConfig;
  resultsMenuLevel1: Array<string>;
  resultsMenuIcons: Array<string>;
  outputInterval: number;
  setOutputInterval: any;
  forms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  interactiveForms: Array<{
    name: string;
    forms: Array<{
      name: string;
      form: Form;
    }>;
  }>;
  quantities: Array<Quantity>;
  units: Array<Unit>;
  parameterList: Array<Variable>;
  uiVariablesList: Array<Variable>;
  getVariableList: (...args: Array<any>) => any;
};

export const StructureContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const StructureProvider = ({ children }: Props) => {
  // FMU selection states
  const [selectedFmu, setSelectedFmu] = useState(undefined);
  const [fmuSelectList, setFmuSelectList] = useState([]);
  // FMU data states
  const [uiSetupConfig, setUiSetupConfig] = useState([]);
  const [uiInputConfig, setUiInputConfig] = useState([]);
  const [variables, setVariables] = useState([]);
  const [uiResultsConfig, setUiResultsConfig] = useState([]);
  const [teams, setTeams] = useState([]);
  const [forms, setForms] = useState([]);
  const [interactiveForms, setInteractiveForms] = useState([]);
  const [isInteractive, setIsInteractive] = useState(false);
  const [inputsAvailable, setInputsAvailable] = useState(false);
  const [outputInterval, setOutputInterval] = useState(1);
  const [parameterList, setParameterList] = useState([]); // includes hidden parameter, too

  const [uiVariablesList, setUiVariablesList] = useState([]); // without hidden parameter

  const [hiddenParameterList, setHiddenParameterList] = useState([]); // hidden parameter only

  // structure
  const [menuLevel1, setMenuLevel1] = useState([]);
  const [menuLevel2, setMenuLevel2] = useState([[]]);
  const [parameterMenuIcons, setParameterMenuIcons] = useState([]);
  const [stepperMenuTexts, setStepperMenuTexts] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [initialInputValues, setInitialInputValues] = useState([]);
  const [resultsMenuLevel1, setResultsMenuLevel1] = useState([]);
  const [resultsMenuIcons, setResultsMenuIcons] = useState(['']);
  // units
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [quantities, setQuantities] = useState<Array<Quantity>>([]);
  // const [isLoadingFmus, setIsLoadingFmus] = useState(true);
  const [isLoadingStructure, setIsLoadingStructure] = useState(true);
  const userDataContext = useUserDataContext();

  // TODO: Move two functions below to parameterContext?

  /**
   * Returns an array of variables that belong to a specific subMenu
   * @param lev1 level1 name
   * @param lev2 level2 name
   * @returns {*}
   */
  const getVariablesOfSubMenu = (lev1: string, lev2: string) => {
    const discardedVariable = {
      alias: 'Discarded Variable',
      name: 'discarded_varibale',
      unit: '-',
    };
    if (isEmpty(uiSetupConfig)) {
      return [discardedVariable];
    }

    const le1 = uiSetupConfig.find((el) => el.name === lev1);
    const subMenu = le1
      ? le1.subMenu
      : [
          {
            name: 'SubMenu',
            variables: [
              {
                alias: 'Variable',
                name: 'Variable',
                unit: '-',
              },
            ],
          },
        ];
    const le2 = subMenu.find((el) => el.name === lev2);
    return le2 ? le2.variables : [discardedVariable];
  };

  /**
   * Get unit of variable
   * @param variableName unit of this variable gets returned
   * @returns {*}
   */
  const getVariableUnit = (variableName: string) => {
    let unit = '-';

    if (isEmpty(variables)) {
      return unit;
    }

    const variable = variables.find((el) => el.name === variableName);

    if (variable) {
      unit = variable.displayUnit && variable.displayUnit !== 'null' ? variable.displayUnit : variable.unit;
      unit = unit === '1' ? '-' : unit;
    }

    return unit;
  };

  /**
   * @description This function maps the FMU parameter with UI parameter and
   * takes the information from structure json file. It also adds 'hidden' parameters,
   * but not stopt_time and output_interval
   * @param values from Formik
   * @returns {*}
   */
  const getMappedFmuUiValues = (values) => {
    const mappedFmuParameter = {};
    const mappedFmuInputs = {};
    // get parameters and values that are in UI and need to be passed to FMU
    // for uiParametersList a backwards compatibility is a added if uiType is not defined
    const fmuParameterList = uiVariablesList.filter((el) => el.uiType === 'fmuParameter' || !el.uiType);
    const fmuInputsList = uiVariablesList.filter((el) => el.uiType === 'fmuInput');
    fmuParameterList.forEach((parameter) => {
      if (parameter.inputType === 'variableInput') {
        // TODO: define code below as function?
        const uiV = values[parameter.name];
        parameter.variableInput.names.forEach((fmuParamList, idxParamList) => {
          fmuParamList.forEach((fmuParam, idxParam) => {
            if (idxParam < uiV.length - 1) {
              mappedFmuParameter[fmuParam] = uiV[idxParam][parameter.variableInput.columnNames[idxParamList]];
            } else if (parameter.variableInput.columnNames[idxParamList] !== 'time') {
              const lastUiValue = uiV[uiV.length - 2][parameter.variableInput.columnNames[idxParamList]];
              const lastTimeValue = uiV[uiV.length - 2].time;
              const timeMax = uiV[uiV.length - 1].time;
              const dummyTimeValue = lastTimeValue + (idxParam / 10) * (timeMax - lastTimeValue);
              mappedFmuParameter[fmuParam] = lastUiValue;
              mappedFmuParameter[parameter.variableInput.names[0][idxParam]] = dummyTimeValue;
            }
          });
        });
      } else if (parameter.inputType === 'Table') {
        // FMU required indices of table attached to name
        values[parameter.name].forEach((array1, rowId) => {
          array1.forEach((el, colId) => {
            // Modelica arrays start at 1, not 0
            mappedFmuParameter[`${parameter.name}[${rowId + 1},${colId + 1}]`] = el;
          });
        });
      } else if (!['start_time', 'stop_time', 'output_interval', 'simulation_name'].includes(parameter.name)) {
        // TODO find better solution to handle parameters that must not be included in FMU parameters
        // don't add stop_time, output_interval and simulation_name to FMU parameters
        mappedFmuParameter[parameter.name] = values[parameter.name];
      }
    });
    // add hidden values to parameter object
    hiddenParameterList.forEach((el) => {
      // don't add start_time, stop_time and output_interval to FMU parameters
      if (el.name !== 'start_time' && el.name !== 'stop_time' && el.name !== 'output_interval') {
        mappedFmuParameter[el.name] = el.start;
      }
    });
    fmuInputsList.forEach((input) => {
      mappedFmuInputs[input.name] = values[input.name];
    });
    return {
      parametersFMU: mappedFmuParameter,
      inputsFMU: mappedFmuInputs,
    };
  };

  /**
   * @description This function gets the subMenu of all mainMenu.
   * It returns an array of arrays.
   * @param uiSetupConfig json data structure of main and sub menus
   * @returns {*}
   */
  const getSubMenuStructure = (uiSetupConfigComplete: UiSetupConfig) => {
    const subMenuStructure = [];
    uiSetupConfigComplete.forEach((uiS) => {
      const subMenuItems = [];
      uiS.subMenu.forEach((uiSel) => {
        subMenuItems.push(uiSel.name);
      });
      subMenuStructure.push(subMenuItems);
    });
    return subMenuStructure;
  };

  /**
   * @description This function builds a list of all variables objects
   * in the uiSetupConfig
   * @param uiSetupConfigComplete two level json data structure
   * @returns {*}
   */
  const getVariableList = (uiSetupConfigComplete: UiSetupConfig) => {
    const variableList = [];

    // iterate over main menu items
    for (let i = 0; i < uiSetupConfigComplete.length; i += 1) {
      const subMenuItems = uiSetupConfigComplete[i].subMenu;
      const subMenuItemsKeys = [];
      subMenuItems.forEach((el) => subMenuItemsKeys.push(el.name));
      // get sub menu items with corresponding variables
      const simVariables = [];
      subMenuItems.forEach((el) => simVariables.push(el.variables));
      variableList.push(simVariables.flatMap((el) => el));
    }

    return variableList.flatMap((el) => el);
  };

  /**
   * @description This function gets the initial variables from the uiSetupConfigComplete
   * for each given mainMenu item.
   * @param uiSetupConfigComplete two level json data structure
   * @param mainMenuItems array of mainMenu names
   * @returns {*}
   */
  const getInitialValues = (uiSetupConfigComplete: UiSetupConfig, mainMenuItems: Array<string>) => {
    const initValues = {};
    // filter uiStructureArr with mainMenuItems
    const uiStructureFiltered = uiSetupConfigComplete.filter((j) => mainMenuItems.includes(j.name));
    // get a flat variable list
    const variableList = getVariableList(uiStructureFiltered);
    // go through variables list and write initial values for Formik
    variableList.forEach((v) => {
      if (v.inputType === 'variableInput') {
        if (v.variableInput) {
          if (v.variableInput.start) {
            const startValues = [];
            v.variableInput.start.forEach((el, indStartValues) => {
              const rowValues = {
                id: indStartValues,
              };
              v.variableInput.columnNames.forEach((name, indColumnName) => {
                rowValues[name] = el[indColumnName];
              });
              startValues.push(rowValues);
            });
            initValues[v.name] = startValues;
          }
        }
      } else if (['Table', 'InputTable'].includes(v.inputType)) {
        initValues[v.name] = v.arrays.start;
        initValues[`${v.name}_dataSource`] = v.dataSource;
      } else if (v.inputType !== 'Header') {
        // make sure that 'Header' are not added to initialValues
        initValues[v.name] = v.start;
      }
    });
    return initValues;
  };

  /**
   * @description is called to parse list of forms from structure json
   * @param uiStructureArr json data structure of main and sub menus
   */
  const getForms = (uiSetupConfigComplete: UiSetupConfig, interactiveSim = false) => {
    const formObjects = [];
    uiSetupConfigComplete.forEach((uiS) => {
      const lvl2Forms = [];

      if (uiS.name !== 'Hidden') {
        uiS.subMenu.forEach((uiSel) => {
          // standard form object if structure json entry empty
          const standardFormObj = interactiveSim
            ? {
                name: 'InteractiveForm',
              }
            : {
                name: 'StandardForm',
              };

          if (!uiSel.form) {
            lvl2Forms.push({
              name: uiSel.name,
              form: standardFormObj,
            });
          } else {
            lvl2Forms.push({
              name: uiSel.name,
              form: uiSel.form,
            });
          }
        });
        formObjects.push({
          name: uiS.name,
          forms: lvl2Forms,
        });
      }
    });
    return formObjects;
  };

  /**
   * @description is called on each selection of an FMU
   */
  const selectFmu = (fmu: any) => {
    try {
      let uiSetupConfigComplete = fmu.ui_setup_config; // parameterMenu;//

      const { inputs } = fmu;
      if (inputs) uiSetupConfigComplete = inputs.concat(uiSetupConfigComplete);

      if (inputs) {
        setInputsAvailable(true);
      } else {
        setInputsAvailable(false);
      }

      setForms(getForms(uiSetupConfigComplete));
      setInteractiveForms(getForms(uiSetupConfigComplete, true)); // not ideal

      setParameterList(getVariableList(uiSetupConfigComplete));
      setUiVariablesList(getVariableList(uiSetupConfigComplete.filter((el) => el.name !== 'Hidden')));
      const hiddenValueStructure = uiSetupConfigComplete.filter((el) => el.name === 'Hidden');

      if (hiddenValueStructure.length > 0) {
        setHiddenParameterList(hiddenValueStructure[0].subMenu[0].variables);
      } else {
        setHiddenParameterList([]);
      }
      setUiInputConfig(inputs);
      setVariables([
        ...fmu.model_description.parameters,
        ...fmu.model_description.inputs,
        ...fmu.model_description.outputs,
      ]);
      setUiResultsConfig(fmu.ui_results_config);
      setTeams(fmu.teams);
      setIsInteractive(false);

      setUiSetupConfig(uiSetupConfigComplete.filter((el) => el.name !== 'Hidden'));

      return true;
    } catch (error) {
      return error;
    }
  };

  /**
   * @description is called to get list of available FMUs
   */
  const getFmuList = () => {
    httpService
      .get(`fmu/${settings.product}/`, {}, 'json', true)
      .then((response) => {
        const listFMU = response.data;
        // sort public FMUs to bottom of list
        const sortedListFMU = [...listFMU.filter((i) => !i.is_public), ...listFMU.filter((i) => i.is_public)];
        // build an array for select component with id and name, for public FMUs add '(public)'
        const selectList = sortedListFMU.map((l) => {
          if (l.is_public)
            return {
              value: l.id,
              label: `${l.display_name} (public)`,
            };
          return {
            value: l.id,
            label: l.display_name,
          };
        });
        setFmuSelectList(selectList);
        return true;
      })
      .catch((error) => error);
  };

  /**
   * @description is called to get data of one FMU,
   * if no id is given, the first FMU in the list is returned
   * @param id of fmu
   */
  const getFmuData = (id?: number) => {
    if (!isEmpty(fmuSelectList)) {
      const idFmu = id || fmuSelectList[0].value;
      httpService
        .get(`fmu/${settings.product}/${idFmu}/`, {}, 'json', true)
        .then((response) => {
          setSelectedFmu(response.data);
          return true;
        })
        .catch((error) => error);
    }
  };

  /**
   * @description handles the initial set of quantities and units
   */
  useMemo(() => {
    const promiseQuantity = httpService.get('units/quantity/');
    const promiseUnit = httpService.get('units/unit/');
    const allPromises = [promiseQuantity, promiseUnit];
    Promise.all(allPromises)
      .then((response) => {
        setQuantities(response[0].data);
        setUnits(response[1].data);
      })
      .catch((error) => error);
  }, []);

  /**
   * @description handles the set of all structure states that require
   * uiSetupConfig and uiResultsConfig information
   * If successful, it sets isLoadingStructure to false.
   */
  useMemo(() => {
    if (!isEmpty(uiSetupConfig)) {
      const menuLevel1Keys = [];
      uiSetupConfig.forEach((el) => menuLevel1Keys.push(el.name));
      setMenuLevel1(menuLevel1Keys);
      setParameterMenuIcons(uiSetupConfig.map((el) => el.icon || ''));
      setStepperMenuTexts(uiSetupConfig.map((el) => el.stepperMenuText || ''));
      setMenuLevel2(getSubMenuStructure(uiSetupConfig));
      setInitialValues(getInitialValues(uiSetupConfig, menuLevel1Keys));

      if (!isEmpty(uiResultsConfig)) {
        setResultsMenuLevel1(uiResultsConfig.map((p) => p.name));
        setResultsMenuIcons(uiResultsConfig.map((p) => p.icon || ''));
      }

      setIsLoadingStructure(false);
    }
  }, [uiSetupConfig, uiResultsConfig]);

  /**
   * @description handles initial input values for changes
   * in inputsAvailable or isInteractive
   */
  useMemo(() => {
    if (!isEmpty(uiSetupConfig)) {
      if (isInteractive && inputsAvailable) {
        setInitialInputValues([getInitialValues(uiSetupConfig, ['Inputs'])]);
      }
    }
  }, [isInteractive, inputsAvailable, uiSetupConfig]);

  /**
   * @description is executed on every change of selectedFmu and
   * if selectedFmu is valid, it calls selectFm()
   */
  useMemo(() => {
    if (selectedFmu && selectedFmu.id) {
      localStorage.setItem('persistentFmuSelection', JSON.stringify(selectedFmu.id));
      if (fmuSelectList.find((fmu) => fmu.value === selectedFmu.id)) selectFmu(selectedFmu);
    }
  }, [selectedFmu]);

  /**
   * @description is executed on every change of fmuSelectList
   * and gets the FMU data for first FMU in list
   */
  useMemo(() => {
    if (storedAccessToken()) {
      const cachedFmu = JSON.parse(localStorage.getItem('cachedFmu') || 'null');
      const persistentFmuSelection = JSON.parse(localStorage.getItem('persistentFmuSelection') || 'null');

      if (!selectedFmu || !selectedFmu.id || !fmuSelectList.find((fmu) => fmu.value === selectedFmu.id)) {
        if (fmuSelectList.find((i) => i.value === cachedFmu)) getFmuData(cachedFmu);
        else if (fmuSelectList.find((i) => i.value === persistentFmuSelection)) getFmuData(persistentFmuSelection);
        else getFmuData();
      } else {
        selectFmu(selectedFmu);
      }
    }
  }, [fmuSelectList]);

  /**
   * @description is executed on every change of user
   * and gets the fmu list
   */
  useMemo(() => {
    if (storedAccessToken()) {
      getFmuList();
    }
  }, [userDataContext.email]);
  return (
    <StructureContext.Provider
      value={{
        getFmuList,
        getFmuData,
        selectedFmu,
        setSelectedFmu,
        fmuSelectList,
        isLoadingStructure,
        uiSetupConfig,
        uiInputConfig,
        isInteractive,
        setIsInteractive,
        inputsAvailable,
        menuLevel1,
        menuLevel2,
        parameterMenuIcons,
        stepperMenuTexts,
        initialValues,
        initialInputValues,
        getMappedFmuUiValues,
        getVariablesOfSubMenu,
        getVariableUnit,
        variables,
        resultsMenuLevel1,
        resultsMenuIcons,
        uiResultsConfig,
        teams,
        outputInterval,
        setOutputInterval,
        forms,
        interactiveForms,
        units,
        quantities,
        parameterList,
        uiVariablesList,
        getVariableList,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};

export const useStructureContext = (): contextType => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useStructureContext must be used within a StructureProvider');
  }

  return context;
};
export const useUnitHelper = (): {
  getQuantityOfUnit: (unitName: string) => Quantity | void;
  getRelatedUnits: (quantity: Quantity) => Array<Unit>;
  getAllUnits: () => Array<Unit>;
  unitConversion: (currentUnitName: string, currentValue: number, newUnitName: string) => number;
  unitsCompatible: (unit1: string, unit2: string) => boolean;
} => {
  const context = useContext(StructureContext);

  if (context === undefined) {
    throw new Error('useUnitConversion must be used within a StructureProvider');
  }

  const { getQuantityOfUnit, getRelatedUnits, getAllUnits, unitConversion, unitsCompatible } = unitsHelper;

  /**
   * @description returns value transformed to new unit,
   * if current and new unit represent the same quantity,
   * else the current value is returned.
   * @param currentUnitName
   * @param currentValue
   * @param newUnitName
   * @return {}
   */
  const prefilledUnitConversion = (currentUnitName: string, currentValue: number, newUnitName: string): number =>
    unitConversion(context.units, context.quantities, currentUnitName, currentValue, newUnitName);

  /**
   * @description checks if both units exist and belong to the same quantity
   * @param unit1
   * @param unit2
   * @return {}
   */
  const prefilledUnitsCompatible = (unit1: string, unit2: string): boolean =>
    unitsCompatible(context.units, context.quantities, unit1, unit2);

  const prefilledGetQuantityOfUnit = (unitName: string): Quantity | void =>
    getQuantityOfUnit(context.units, context.quantities, unitName);

  const prefilledGetRelatedUnits = (quantity: Quantity): Array<Unit> => getRelatedUnits(context.units, quantity);

  const prefilledGetAllUnits = (): Array<Unit> => getAllUnits(context.units);

  return {
    getQuantityOfUnit: prefilledGetQuantityOfUnit,
    getRelatedUnits: prefilledGetRelatedUnits,
    getAllUnits: prefilledGetAllUnits,
    unitConversion: prefilledUnitConversion,
    unitsCompatible: prefilledUnitsCompatible,
  };
};
export default StructureProvider;
