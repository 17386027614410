// @ts-nocheck
import React, { useState, useContext, useMemo } from 'react';
import httpService, { storedAccessToken } from '../../services/http';
import settings from '../../settings';
import { useStructureContext } from '../StructureProvider';
// import { plotResults } from './dummyContextData';
// import getPlotData from '../../components/charts/Charts/helper';
import chartOptionConstants from '../../components/charts/constants/chartOptionConstants';
import chartDashStyles from '../../components/charts/constants/chartDashStyles';
import chartMarkerSymbols from '../../components/charts/constants/chartMarkerSymbols';

type Props = {
  children: React.ReactNode;
};
type ResponseType = {
  name: string;
  id: number;
  fmu: Object;
  ui_results_data: any;
  ui_setup_data: any;
  start_time: number;
  stop_time: number;
  output_interval: number;
  parameters: Object;
  parametersFMU: Object;
  inputs: Object;
};
export type contextType = {
  isLoading: boolean;
  setIsLoading: any;
  isSimulating: boolean;
  setIsSimulating: any;
  resultData: any;
  uiResultsData: any;
  uiSetupData: any;
  setQuery: any;
  timeIndex: number;
  setTimeIndex: any;
  stopTime: Array<number>;
  outputInterval: Array<number>;
  invalidateCachedResponse: (id: number) => void;
  addPlotDataToStructure: () => void;
  plotsStructureWithData: Array<Array<{ name: string; type: string; series: Object; data: Array<Object> }>>;
  getSelectedPlotData: (
    name: string,
    customColors?: any
  ) => Array<{
    color: any;
    dashStyle: any;
    data: any;
    isMulti: boolean;
    markerSymbol: any;
    name: string;
    nrSims: any;
    patternIndex: any;
    simName: any;
    sortIndex: any;
    varName: any;
    xAxisRange: any | null;
    yAxisRange: any | null;
    key: string;
  }>;
};

export const PlotDataContext: React.Context<contextType | undefined> =
  React.createContext<contextType | undefined>(undefined);

const PlotDataProvider = ({ children }: Props) => {
  // states that hold data of currently selected simulations:
  const [resultData, setResultData] = useState([]); // updated (plot_data and parameters) version of plot response data
  const [uiResultsData, setUiResultsData] = useState([]);
  const [uiSetupData, setUiSetupData] = useState();
  const [plotsStructureWithData, setPlotsStructureWithData] = useState([[{}]]);

  const [cachedResponses, setCachedResponses] = useState({});
  const [query, setQuery] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSimulating, setIsSimulating] = useState(false);

  const [timeIndex, setTimeIndex] = useState(0);
  const [stopTime, setStopTime] = useState([1]);
  const [outputInterval, setOutputInterval] = useState([1]);

  const { uiResultsConfig: resultsUiConfig, initialValues } = useStructureContext();

  /**
   * @description handles the uiResultsData and uiSetupData for each app
   * they are merged with up-to-date data.
   * isLoading is set false at the end.
   * @param responseData Array of uiResultsData from API response
   * @returns {*}
   */
  const handleResultData = (responseData: Array<ResponseType>) => {
    // set stop time and output interval so that the time index can be calculated in timeseries plot component
    // stop time and output interval are set for all results in case a differentiation is necessary in the future
    // in ResultsPlotComponent the first entry is used
    setStopTime(responseData.map((res) => (res.stop_time ? res.stop_time : 1)));
    setOutputInterval(responseData.map((res) => (res.output_interval ? res.output_interval * 1000 : 1)));
    // get plots from all result data
    const resultPlotData = responseData.map((r) => {
      return r.ui_results_data;
    });
    setUiResultsData([...resultPlotData]);
    if (!resultPlotData.every((value) => value === null)) setUiResultsData([...resultPlotData]);

    // get parameters from all result data
    const resultUiParameter = responseData.map((r) => {
      return r.ui_setup_data;
    });
    // make sure new parameters (after an update) are also considered
    const completeUiParameter = resultUiParameter.map((r) => ({
      ...initialValues,
      ...r,
    }));
    if (!completeUiParameter.every((value) => value === null)) setUiSetupData(completeUiParameter);

    const results = [...responseData];
    for (let i = 0; i < results.length; i += 1) {
      results[i].parameters = completeUiParameter[i];
      results[i].ui_results_data = resultPlotData[i];
    }

    setResultData(results);
    // make sure all results are loaded
    if (resultPlotData && completeUiParameter && results) setIsLoading(false);
  };

  /**
   * @description get request for ui_results_data
   * @param queries Array of queries
   * @returns {Promise}
   */
  const getPlotData = (queries: Array<number>) => {
    const promiseResultData = httpService.get(
      `simulation/${settings.product}/ui_results_data/`,
      {
        headers: null,
        id: JSON.stringify(queries),
      },
      {},
      {},
      true
    );
    return Promise.resolve(promiseResultData);
  };

  /**
   * Get data for one plot of each simulation
   * and handle multiple simulations by adding simulation name and adjusting styles of markers, lines etc.
   * @param name plotData of this plot gets returned
   * @param customColors array of custom colors
   * @returns {plotData}
   */
  const getSelectedPlotData = (
    name: string,
    plt: Plot,
    customColors?: any
  ): Array<{
    color: any;
    dashStyle: any;
    data: any;
    isMulti: boolean;
    markerSymbol: any;
    name: string;
    nrSims: any;
    patternIndex: any;
    simName: any;
    sortIndex: any;
    varName: any;
    xAxisRange: any | null;
    yAxisRange: any | null;
    key: string;
  }> => {
    const plotColors = customColors ? customColors.concat(chartOptionConstants.colors) : chartOptionConstants.colors;
    let orderedPlotData = [];
    const isMulti = resultData.length > 1;

    // loop through each simulation
    resultData.forEach((r, rIndex) => {
      const parsedPlotData = r.ui_results_data;
      let series = parsedPlotData[name];
      const pltType = plt.type;

      // For multiple simulation results, add simulation name, handle styles and indizes
      if (isMulti && series) {
        if (pltType === 'interactive_svg') {
          const matchingUiResultsData = resultData.find(
            (res, resId) => res.name === plt.resName && resId === plt.resId
          )?.ui_results_data;
          series = matchingUiResultsData ? matchingUiResultsData[name] : parsedPlotData[name];
        } else {
          series.forEach((ser, serIndex) => {
            const serName = series.length > 1 ? `${r.name} (${ser.name})` : `${r.name}`;
            orderedPlotData.push({
              name: serName,
              data: ser.data,
              color: plotColors[rIndex],
              dashStyle: chartDashStyles[serIndex],
              markerSymbol: chartMarkerSymbols[serIndex],
              patternIndex: rIndex,
              sortIndex: rIndex,
              isMulti: true,
              varName: ser.name,
              simName: r.name,
              nrSims: resultData.length,
              xAxisRange: ser.xAxisRange ? ser.xAxisRange : null,
              yAxisRange: ser.yAxisRange ? ser.yAxisRange : null,
              key: `${serName}_${rIndex}`,
            });
          });
          series = orderedPlotData.sort((a, b) => a.sortIndex - b.sortIndex);
        }
      }
      orderedPlotData = series || [...orderedPlotData]; // in case sort didn't work
    });

    return orderedPlotData;
  };

  const addPlotDataToStructure = () => {
    if (uiResultsData.length > 0) {
      const isMulti = resultData.length > 1;
      const plotsWithDataTmp = resultsUiConfig.map((pltStr) => {
        const { plots } = pltStr;
        // copy needs to be used to not override plots -> wrong behaviour if selected simulations change
        const plotsCopy = [...plots];
        const hasInteractiveSvgPlot = plotsCopy.some((el) => el.type === 'interactive_svg');
        if (isMulti && hasInteractiveSvgPlot) {
          const interactiveSvgPlotId = plotsCopy.findIndex((el) => el.type === 'interactive_svg');
          const interactiveSvgPlot = plotsCopy.find((el) => el.type === 'interactive_svg');
          resultData.forEach((res, resId) => {
            const isAlreadyInPlotsArray = plotsCopy.some((el) => el.resName === res.name);
            if (!isAlreadyInPlotsArray) {
              if (resId === 0) {
                plotsCopy.splice(interactiveSvgPlotId, 1, {
                  ...interactiveSvgPlot,
                  isMulti: true,
                  resName: res.name,
                  resId,
                });
              } else {
                plotsCopy.splice(interactiveSvgPlotId + resId, 0, {
                  ...interactiveSvgPlot,
                  isMulti: true,
                  resName: res.name,
                  resId,
                });
              }
            }
          });
        }
        const plotsToShow = isMulti ? plotsCopy : plots;
        return plotsToShow.map((plt) => ({ ...plt, data: getSelectedPlotData(plt.name, plt, plt.customColors) }));
      });
      setPlotsStructureWithData(plotsWithDataTmp);
    }
  };

  /**
   * @description executed on change of query
   * handles cached data as well as the call for new data.
   * The data are stored as raw request data and handleResultData()
   * is called every time.
   */
  useMemo(() => {
    if (storedAccessToken()) {
      // retrieves the data already cached and stores them in requestedAndCachedData
      const cachedIds = Object.keys(cachedResponses);
      const nonCachedIds = query.filter((q) => cachedIds.includes(JSON.stringify(q)) === false);
      const cachedAndRequestedIds = query.filter((q) => cachedIds.includes(JSON.stringify(q)));
      const cachedResponseValues = Object.values(cachedResponses) as any;
      const requestedAndCachedData = cachedResponseValues.filter((c) => cachedAndRequestedIds.includes(c.id));

      // if there are uncached data, call getPlotData() and cache the response
      // call handleResultData() in each case
      if (nonCachedIds.length > 0) {
        getPlotData(nonCachedIds)
          .then((response) => {
            const respData = response.data;
            const tmpCachedResponses = cachedResponses;
            for (let i = 0; i < respData.length; i += 1) {
              const data = respData[i];
              data.ui_results_data = { ...data.ui_results_data };
              tmpCachedResponses[data.id] = data;
            }
            setCachedResponses(tmpCachedResponses);
            handleResultData([...respData, ...requestedAndCachedData]);
            return true;
          })
          .catch((error) => error);
      } else {
        handleResultData(requestedAndCachedData);
      }
    }
  }, [query]);

  const invalidateCachedResponse = (id: number): void => {
    const newCachedResponses = { ...cachedResponses };
    delete newCachedResponses[id];
    setCachedResponses(newCachedResponses);
  };

  return (
    <PlotDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isSimulating,
        setIsSimulating,
        resultData,
        uiResultsData,
        uiSetupData,
        setQuery,
        timeIndex,
        setTimeIndex,
        stopTime,
        outputInterval,
        invalidateCachedResponse,
        addPlotDataToStructure,
        plotsStructureWithData,
        getSelectedPlotData,
      }}
    >
      {children}
    </PlotDataContext.Provider>
  );
};

export const usePlotDataContext = (): contextType => {
  const context = useContext(PlotDataContext);

  if (context === undefined) {
    throw new Error('usePlotDataContext must be used within a PlotDataProvider');
  }

  return context;
};
export default PlotDataProvider;
